import React from "react"
import { graphql } from "gatsby"

import { Layout, Event } from "../components"

const EventPage = ({data}) => {
  const event = data.event

  return (
    <Layout>
      <div className="py-12 bg-white dark:bg-gray-800">
        <button className="mt-2 mx-4 sm:mx-16 text-xl leading-8 font-bold tracking-tight text-gray-500 dark:text-gray-300 sm:text-xl" onClick={()=>{window.history.back()}}>{"< Back"}</button>
        <Event event={event} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    event: wpEvent( id: { eq: $id }) {
      title
      data: acfEventData {
        date
        description
        youtubeId
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

export default EventPage
